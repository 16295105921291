import LysRequest from "../request/index"

// 根据书籍id获取书籍目录内容
export const reqBookCatalogContent = (data) => {
  return LysRequest.request({
    url: `/bookContent/getBookContent`,
    method: "post",
    data
  })
}

// 根据书籍id获取书籍目录
export const reqBookCatalog = (bookId) => {
  return LysRequest.request({
    url: `/book/catalogByBookId/${bookId}`,
    method: "get"
  })
}

// 查询书籍词条详情
export const reqBookConceptEntry = (data) => {
  return LysRequest.request({
    url: `/entry/getInfoById`,
    method: "post",
    data
  })
}

// 创建/修改 笔记
export const reqBookNote = (data) => {
  return LysRequest.request({
    url: `/bookNote/createOrUpdateBookNote`,
    method: "post",
    data
  })
}

// 查询用户章笔记列表
export const reqBookNoteList = (data) => {
  return LysRequest.request({
    url: `/bookNote/searchBookNotePage`,
    method: "post",
    data
  })
}

// 删除用户笔记
export const reqBookNoteDel = (data) => {
  return LysRequest.request({
    url: `/bookNote/deleteBookNote`,
    method: "post",
    data
  })
}

// 章查询视频/音频列表
export const reqBookChapterExplainList = (data) => {
  return LysRequest.request({
    url: `/bookVideo/searchBookVideoPage`,
    method: "post",
    data
  })
}

// 章查询视频详情
export const reqBookChapterVideoDetail = (data) => {
  return LysRequest.request({
    url: `/bookVideo/searchBookVideoDetail`,
    method: "post",
    data
  })
}

// 章查询音频详情
export const reqBookChapterAudioDetail = (data) => {
  return LysRequest.request({
    url: `/bookVideo/searchBookAudioDetail`,
    method: "post",
    data
  })
}


// 记录书籍阅读时间
export const reqBookRecordReadingTime = (data)=>{
  return LysRequest.request({
    url: `/book/recordReadingTime`,
    method: "post",
    data
  })
}

// 收藏书籍 不同模块
export const reqBookCollectType = (data)=>{
  return LysRequest.request({
    url: `/bookPracticeQuestions/collectionQuestion`,
    method: "post",
    data
  })
}

// 取消收藏书籍 不同模块
export const reqBookDeleteCollectType = (data)=>{
  return LysRequest.request({
    url: `/bookPracticeQuestions/deleteCollectionQuestion`,
    method: "post",
    data
  })
}

// 批量查询词条内容
export const reqBookEntryAll = (data)=>{
  return LysRequest.request({
    url: `/entry/getInfoByIds`,
    method: "post",
    data
  })
}

// 批量查询例题内容
export const reqBookExampleAll = (data)=>{
  return LysRequest.request({
    url: `/bookPracticeExampleQuestions/queryBookQuestionsInfoByIds`,
    method: "post",
    data
  })
}

// 批量查询小题列表
export const reqBookPracticeAll = (data)=>{
  return LysRequest.request({
    url: `/bookPracticeQuestions/queryDetailListByIds`,
    method: "post",
    data
  })
}

// 批量查询ggb列表
export const reqBookGgbAll = (data)=>{
  return LysRequest.request({
    url: `/chart/byIds`,
    method: "post",
    data
  })
}

// 查询例题答案
export const reqBookExampleAnswer = (data)=>{
  return LysRequest.request({
    url: `/bookPracticeExampleQuestions/queryBookQuestionAnswer`,
    method: "post",
    data
  })
}

// 查询练习题答案
export const reqBookPracticeAnswer = (data)=>{
  return LysRequest.request({
    url: `/bookPracticeQuestions/queryBookQuestionAnswer`,
    method: "post",
    data
  })
}

// 查询英语词典
export const reqGetWord = (data)=>{
  return LysRequest.request({
    url: `/word/getWord`,
    method: "post",
    data
  })
}

// 字典列表
export const reqChineseDict = (data)=>{
  return LysRequest.request({
    url: `/chineseDict/list`,
    method: "post",
    data
  })
}

// 查询语文词典
export const reqChineseWordDict = (data)=>{
  return LysRequest.request({
    url: `/chineseWordDict/list`,
    method: "post",
    data
  })
}

// 查询用户题目答题记录
export const reqUserAnswerSubmitRecords = (data)=>{
  return LysRequest.request({
    url: `/bookPracticeQuestions/getUserAnswerRecord`,
    method: "post",
    data
  })
}

// 字典单词添加收藏
export const reqWordAddFavorites = (data)=>{
  return LysRequest.request({
    url: `/word/addFavorites`,
    method: "post",
    data
  })
}

// 字典单词取消收藏
export const reqWordDeleteFavorites = (data)=>{
  return LysRequest.request({
    url: `/word/deleteFavorites`,
    method: "post",
    data
  })
}