export default class SpeakVoice {
  constructor() {
    this.synth = window.speechSynthesis; // 启用文本
    this.instance = new SpeechSynthesisUtterance();
    this.voices = [];
    this.initVoice();
  }

  // 初始化
  initVoice() {
    this.instance.volume = 1; // 声音音量：1，范围从0到1
    this.instance.rate = 1; // 设置语速：1，范围从0到100
    this.instance.lang = "zh-CN"; // 使用的语言:中文
  }

  // 语音开始
  handleSpeak() {
    this.synth.speak(this.instance); // 播放
  }

  // 语音队列重播
  handleReply(text) {
    this.instance.text = text;
    this.handleCancel();
    this.handleSpeak();
  }

  // 语音队列删除 , 删除队列中所有的语音.如果正在播放,则直接停止
  handleCancel() {
    this.synth.cancel();
  }

  // 语音暂停, 暂停语音该次语音播放
  handleStop() {
    this.synth.pause();
  }

  // 恢复暂停的语音
  handleResume() {
    this.synth.resume();
  }

  // 声音切换
  setVoiceType(voiceName) {
    const voice = this.voices.find((voice) => voice.name === voiceName);
    if (voice) {
      this.instance.voice = voice;
    } else {
      console.warn("指定的语音未找到:", voiceName);
    }
  }

  // 获取可用的中文语音
  async getSpeechCnVoices() {
    try {
      const voices = await this.getSpeechVoices();
      const cnVoices = voices.filter(
        (item) => item.lang.startsWith("zh-") && item.localService
      ); 
      if (cnVoices.length == 0) {
        throw new Error("没有可用的中文语音！");
      }
      
      this.voices = cnVoices;
      return cnVoices;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  // 异步获取所有可用的语音
  async getSpeechVoices() {
    // 返回一个新的 Promise
    return new Promise((resolve) => {
      // 如果当前没有可用的语音，等待 onvoiceschanged 事件触发
      if (this.synth.getVoices().length === 0) {
        this.synth.onvoiceschanged = () => resolve(this.synth.getVoices());
      } else {
        // 如果已经有可用的语音，立即解析 Promise
        resolve(this.synth.getVoices());
      }
    });
  }
}

