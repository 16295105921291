import { defineStore } from "pinia"
import { ElMessage } from "element-plus"
import UseStoreUser from "@/store/modules/user"

import {
  requestNav,
  requestBook,
  requestUserAddBook,
  requestDeleteBookCollect,
  requestAddBookCollect,
  requestBookPrice,
  requestZfbPay,
  requestWxPay,
  requestMessageList,
  requestSendMessage,
  requestPayStatus,
  requestBookRank,
  requestGradesAndSubjects,
  requestQuestionType,
  requestQuestionList,
  requestUserOnLine,
  requestHotVideoList,
  requestHintMessageCount,
  requestHintMessageUnread,
  requestInstrumentsList,
  requestHomeBannerList
} from "@/service/modular/home"

const UseStoreHome = defineStore("storeHome", {
  state: () => ({
    storeUser: UseStoreUser(),
    navList: [], // 导航的数据
    gradeActive: "", // 选中的导航
    hoverGradeToggle: false, // 课本导航开关
    gradeType: [
      {
        id: 1,
        name: "课内"
      },
      {
        id: 2,
        name: "课外"
      },
      {
        id: 3,
        name: "习题"
      }
    ],
    gradeTypeActive: { id: 1, name: "课内" },
    gradeBooks: [],
    userBookCollect: [],

    bookSku: [],

    message: {
      records: []
    },

    hotBook: [],

    topicContent: {
      list: [],
      total: 0
    },

    userHint: {
      count: 0,
      messageUnread: []
    },

    HotVideo: {
      list: [],
      total: 0
    },

    questionType: [],

    cs: [], 
    instruments: [],
    bannerList:[]
  }),
  actions: {

    async requestHomeBannerListAction(){
      let res = await requestHomeBannerList()
      if (res.code == 200) {
        this.bannerList = res.data
      }
    },

    async requestQuestionListAction(data) {
      let res = await requestQuestionList(data)
      if (res.code == 200) {
        this.topicContent.list = res.data.list
        this.topicContent.total = res.data.total
      }
    },

    requestGradesAndSubjectsAction() {
      return new Promise(async (resolve, reject) => {
        let res = await requestGradesAndSubjects()
        if (res.code == 200) {
          resolve(res.data)
        }
      })
    },

    requestQuestionTypeAction() {
      return new Promise(async (resolve, reject) => {
        let res = await requestQuestionType()
        if (res.code == 200) {
          this.questionType = res.data
        }
      })
    },

    async requestNavAction() {
      let res = await requestNav()
      this.navList = res.data
    },

    async requestBookAction() {
      let data = {
        subjectId: this.gradeActive.id,
        type: this.gradeTypeActive.id,
        userId: this.storeUser.userId
      }
      let res = await requestBook(data)

      this.gradeBooks = res.data
    },

    async requestUserAddBookAction(bookId) {
      let id = this.storeUser.userId
      let data = { bookId: bookId, userId: id }
      let res = await requestUserAddBook(data)
      if (res?.code == 200) {
        ElMessage({ message: "添加成功", type: "success" })
      } else if (res.code == 500) {
        ElMessage({ message: "学习课程已有，请勿重复添加", type: "warning" })
      }
    },

    async requestBookRankAction(data) {
      let res = await requestBookRank(data)
      this.hotBook = res.data
    },

    // async requestUserBookCollectAction(){
    //  let res = await requestUserBookCollect(JSON.parse(localStorage.getItem('project-userInfo'))?.id)
    //  this.userBookCollect = res.data
    // },

    requestAddBookCollectAction(info) {
      return new Promise(async (resolve, reject) => {
        let res = await requestAddBookCollect(info)
        if (res.code == 200) {
          ElMessage({ message: "添加收藏 成功", type: "success" })
          resolve(true)
        }
      })
    },

    requestDeleteBookCollectAction(info) {
      return new Promise(async (resolve, reject) => {
        let res = await requestDeleteBookCollect(info)
        if (res.code == 200) {
          resolve(true)
          ElMessage({ message: "取消收藏 成功", type: "success" })
        }
      })
    },

    async requestBookPriceAction(bookId) {
      return new Promise(async (resolve, reject) => {
        let res = await requestBookPrice(bookId)
        if (res.code == 200) {
          this.bookSku = res.data
          resolve(true)
        }
      })
    },

    async requestPayAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestZfbPay(parameter)
        if (res.code == 200) {
          resolve(res.data)
        }
      })
    },

    async requestWxPayAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestWxPay(parameter)
        if (res.code == 200) {
          resolve(res.data)
        }
      })
    },

    async requestPayStatusAction(orderNumber) {
      return new Promise(async (resolve, reject) => {
        let res = await requestPayStatus(orderNumber)
        if (res.code == 200 && res.data) {
          resolve(res.data)
        }
      })
    },

    async requestMessageListAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestMessageList(parameter)
        
        if (res.code == 200) {
          this.message.records = res.data.records
          resolve(true)
        } else {
          localStorage.removeItem("project-token")
          resolve(false)
        }
      })
    },

    async requestSendMessageAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestSendMessage(parameter)
        if (res.code == 200) {
          resolve(true)
        }
      })
    },

    async requestUserOnLineAction(parameter) {
      let res = await requestUserOnLine(parameter)
      if (res.code == 500) {
        localStorage.removeItem("project-token")
      }
    },

    // 热门视频
    async requestHotVideoListAction(data) {
      return new Promise(async (resolve, reject) => {
        let res = await requestHotVideoList(data)
        if (res.code == 200) {
          this.HotVideo.list = res.data.list
          this.HotVideo.total = res.data.total
          resolve(true)
        }
      })
    },

    // 查询当前用户未读消息数量
    async requestHintMessageCountAction(data) {
      let res = await requestHintMessageCount(data)
      if (res.code == 200) {
        this.userHint.count = res.data
      }
    },

    // 查询系统通知-未读
    async requestHintMessageUnreadAction(data) {
      let res = await requestHintMessageUnread(data)
      if (res.code == 200) {
        this.userHint.messageUnread = res.data
      }
    },

    async requestGetInstrumentsAction(data) {
      let res = await requestInstrumentsList(data)
        if (res.code == 200 && res.data) {
          this.instruments = res.data
        }
    }
  }
})

export default UseStoreHome
