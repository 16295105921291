import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b37d1af6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "dragDialog-content"
};
const _hoisted_2 = {
  class: "noteHeader"
};
const _hoisted_3 = {
  class: "noteHeader-left"
};
const _hoisted_4 = {
  class: "content"
};
import { onMounted, reactive, ref } from "vue";
import UseStoreCommon from "@/store/modules/common";
export default {
  __name: 'index',
  props: {
    minW: {
      type: [Number, String],
      default: 430
    },
    minH: {
      type: [Number, String],
      default: 150
    },
    width: {
      type: [String, Number],
      default: 444
    },
    height: {
      type: [String, Number],
      default: 430
    },
    show: {
      type: Boolean,
      default: false
    },
    initialLeft: {
      type: [String, Number],
      default: 500
    },
    initialTop: {
      type: [String, Number],
      default: 80
    }
  },
  emits: ["resizingEmit"],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    const storeCommon = UseStoreCommon();
    let select = ref(false);
    let onOff = ref(props.show);
    const dragDialogOver = () => {
      select.value = true;
    };
    const dragDialogOut = () => {
      select.value = false;
    };

    // 拖拽的配置

    const dragData = reactive({
      x: props.initialLeft,
      y: props.initialTop,
      h: props.height,
      w: props.width,
      active: true
    });
    // 拖拽的事件
    const print = val => {};
    const resizingFn = obj => {
      emit("resizingEmit", obj.h);
    };
    const findParentElement = (child, parentTag) => {
      let parent = child.parentNode;
      while (parent && parent.className.indexOf(parentTag) == -1) {
        parent = parent.parentNode ? parent.parentNode : null;
      }
      return parent;
    };
    const dragDialogClick = e => {
      let parentTable = findParentElement(e.target, "vdr-container");
      if (parentTable) {
        storeCommon.dialogIndex += 1;
        parentTable.style.zIndex = storeCommon.dialogIndex;
      }
    };
    __expose({
      select,
      onOff,
      dragData
    });
    return (_ctx, _cache) => {
      const _component_Vue3DraggableResizable = _resolveComponent("Vue3DraggableResizable");
      return _unref(onOff) ? (_openBlock(), _createBlock(_component_Vue3DraggableResizable, {
        key: 0,
        classNameDraggable: "draggable",
        initW: props.width,
        initH: props.height,
        minW: props.minW,
        minH: props.minH,
        x: dragData.x,
        "onUpdate:x": _cache[0] || (_cache[0] = $event => dragData.x = $event),
        y: dragData.y,
        "onUpdate:y": _cache[1] || (_cache[1] = $event => dragData.y = $event),
        w: dragData.w,
        "onUpdate:w": _cache[2] || (_cache[2] = $event => dragData.w = $event),
        h: dragData.h,
        "onUpdate:h": _cache[3] || (_cache[3] = $event => dragData.h = $event),
        active: dragData.active,
        "onUpdate:active": _cache[4] || (_cache[4] = $event => dragData.active = $event),
        draggable: _unref(select),
        resizable: true,
        parent: true,
        onActivated: _cache[5] || (_cache[5] = $event => print('activated')),
        onDeactivated: _cache[6] || (_cache[6] = $event => print('deactivated')),
        onDragStart: _cache[7] || (_cache[7] = $event => print('drag-start')),
        onResizeStart: _cache[8] || (_cache[8] = $event => print('resize-start')),
        onDragging: _cache[9] || (_cache[9] = $event => print('dragging')),
        onResizing: resizingFn,
        onDragEnd: _cache[10] || (_cache[10] = $event => print('drag-end')),
        onResizeEnd: _cache[11] || (_cache[11] = $event => print('resize-end')),
        onClick: dragDialogClick
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "noteHeader-left")]), _createElementVNode("div", {
          class: "noteHeader-right",
          onMouseover: dragDialogOver,
          onMouseout: dragDialogOut
        }, [_renderSlot(_ctx.$slots, "noteHeader-right")], 32)]), _createElementVNode("div", _hoisted_4, [_renderSlot(_ctx.$slots, "noteContent")])])]),
        _: 3
      }, 8, ["initW", "initH", "minW", "minH", "x", "y", "w", "h", "active", "draggable"])) : _createCommentVNode("", true);
    };
  }
};