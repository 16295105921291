import { createApp } from "vue"
import App from "./App.vue"
import pinia from "@/store"
import router from "./router"

import "vue3-video-play/dist/style.css"
import vue3videoPlay from "vue3-video-play"
import Vue3DraggableResizable from "vue3-draggable-resizable"
import "vue3-draggable-resizable/dist/Vue3DraggableResizable.css"

import "normalize.css"
import "@/assets/css/index.css"

import "element-plus/theme-chalk/el-loading.css"
import "element-plus/theme-chalk/el-message.css"

// 引入阿里图标样式文件
import "./assets/iconfont/iconfont.css"
// 引入全局可用阿里图标js文件
import "./assets/iconfont/iconfont.js"
// 引入自己设置的全局icon基础样式，一般用于规定基础的svg宽高和图标大小
// import './assets/css/iconfont.css'

import "@/utils/mouseAnimation.js"

//上线之后如果有警告 要不要展示
import "@/utils/mathjax"
import "mathjax/es5/tex-svg"

import Empty from "@/components/Empty/index.vue"

createApp(App)
  .use(pinia)
  .use(router)
  .use(vue3videoPlay)
  .use(Vue3DraggableResizable)
  .component("Empty", Empty)
  .mount("#app")

document.title = "爱书网 让学习更方便"
