import { onMounted } from "vue";
import UseStoreBook from "@/store/modules/book"

export const findExplainModule = () => {
  const storeBook = UseStoreBook()
  onMounted(() => {
    window.explainIconClick = explainIconClick
  })

  const explainIconClick = (id,type) => {
    storeBook.reqBookChapterExplainDetailAction({
      id,type
    })
  }

  const filtration = (data)=>{
      let regex = /★(videoId|audioId):\d+★/g
      let matches = data?.match(regex)
      if (matches?.length > 0) {
        for (const key of matches) {
          let id = key.match(/\d+/g)
          if (key.indexOf("videoId") != -1) {
            data = data.replace(
              key,
              `<span id="${id}" class='explain-play-icon  iconfont icon-shipin2' onclick="explainIconClick(${id},1)"></span>`
            )
          } else {
            data = data.replace(
              key,
              `<span id="${id}" class='explain-play-icon  iconfont icon-yinpin' onclick="explainIconClick(${id},2)"></span>`
            )
          }
        }
      } 
      return data
  }

  return { filtration }
}